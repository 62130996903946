import React, { Component } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import ReactApexChart from "react-apexcharts"

import axios from 'axios';

import PropTypes from 'prop-types';

import { collection, doc, getDoc, onSnapshot, setDoc } from '@firebase/firestore';

import db from "../../firebase";

class WalletBalance extends Component {
  constructor(props) {

    super(props)
    this.state = {
      ethvalue: 0,
      ethbal: 0,
      rvnbal: 0,
      rvnvalue: 0,
      xchbal: 0,
      xchvalue: 0,
      total: 0,
      series: [100, 0, 0],
      walletOptions: {
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: "35%",
              background: "transparent",
              image: void 0,
            },
            track: {
              show: !0,
              startAngle: void 0,
              endAngle: void 0,
              background: "#f2f2f2",
              strokeWidth: "97%",
              opacity: 1,
              margin: 12,
              dropShadow: {
                enabled: !1,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
              },
            },
            dataLabels: {
              name: {
                show: !0,
                fontSize: "16px",
                fontWeight: 600,
                offsetY: -10,
              },
              value: {
                show: !0,
                fontSize: "14px",
                offsetY: 4,
                formatter: function (e) {
                  return e + "%"
                },
              },
              total: {
                show: !0,
                label: "Total",
                color: "#373d3f",
                fontSize: "16px",
                fontFamily: void 0,
                fontWeight: 600,
                formatter: function (e) {
                  return (
                    e.globals.seriesTotals.reduce(function (e, t) {
                      return e + t
                    }, 0) + "%"
                  )
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        colors: ["#3452e1", "#f15b22", "#3AAC59"],
        labels: ["Ethereum", "RavenCoin", "Chia"],
        legend: { show: !1 },
      },
    }
    WalletBalance.propTypes = {
      ethvalue: PropTypes.any,
      rvnvalue: PropTypes.any,
      xchvalue: PropTypes.any,
      };    
  }

  componentDidMount = async () => {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    const docref2 = doc(db, "users", obj.uid,)
    let thisdoc = await getDoc(docref2)
    let ethstring = '';
    let rvnstring = '';
    let xchstring = '';
    if (thisdoc._document != null) {
      ethstring = thisdoc._document.data.value.mapValue.fields.eth.stringValue;
      rvnstring = thisdoc._document.data.value.mapValue.fields.rvn.stringValue;
      xchstring = thisdoc._document.data.value.mapValue.fields.xch.stringValue;
      this.setState({eth: thisdoc._document.data.value.mapValue.fields.eth.stringValue, rvn: thisdoc._document.data.value.mapValue.fields.rvn.stringValue, xch: thisdoc._document.data.value.mapValue.fields.xch.stringValue})
    }

    let endethbal = 0;
    let endrvnbal = 0;
    let endxchbal = 0;
    
    await
     axios
    .get("https://api.etherscan.io/api?module=account&action=balance&address="+ethstring+"&tag=latest&apikey=P8C9B7XMGH2F1BI9P36JM6FFPFUP2AGEB8")
    .then(response => {
      let thisbal = (Number(response.data.result)* 0.000000000000000001).toFixed(6);
 

      this.setState({ ethbal: thisbal })

      let ethbalvalue = this.props.ethvalue;
      ethbalvalue = (this.props.ethvalue*thisbal).toFixed(2);

      this.setState({ ethvalue: ethbalvalue})

      endethbal = ethbalvalue;     

    });
    await
     axios
    .get("https://xchscan.com/api/account/balance?address="+xchstring)
    .then(response => {
      let thisbal = Number(response.data.xch).toFixed(4);
      console.log("https://xchscan.com/api/account/balance?address="+xchstring);

      this.setState({ xchbal: thisbal })

      let xchbalvalue = this.props.xchvalue;
      xchbalvalue = (xchbalvalue*thisbal).toFixed(2);
      this.setState({ xchvalue: xchbalvalue})

      endxchbal = xchbalvalue;

    });
    await
    axios
    .get("https://api.yourcointracker.com/index.php?address="+rvnstring)
    .then(response => {
      let thisbal = response.data.balance;
 

      this.setState({ rvnbal: thisbal })

        
      let rvnbalvalue = this.props.rvnvalue;
      rvnbalvalue = (rvnbalvalue*thisbal).toFixed(2);
      this.setState({ rvnvalue: rvnbalvalue})

      let endtotal  = (Number(this.state.rvnvalue)+Number(this.state.ethvalue)+Number(this.state.xchvalue));
      this.setState({ total: endtotal})

      let val1 = Number(((Number(this.state.ethvalue)/endtotal)*100).toFixed(2));
      let val2 = Number(((Number(this.state.rvnvalue)/endtotal)*100).toFixed(2));
      let val3 = Number(((Number(this.state.xchvalue)/endtotal)*100).toFixed(2));

      this.setState({ series: [val1, val2, val3]})

    });

  }


  render() {
    return (
      <React.Fragment>
        <Col xl="12">
          <Card>
            <CardBody>
              <div className="float-end">
              </div>
              <h4 className="card-title mb-3">Wallet Balance</h4>

              <Row>
                <Col lg="4">
                  <div className="mt-4">
                    <p>Available Balance</p>
                    <h4>$ {this.state.total}</h4>
                  </div>
                </Col>

                <Col lg="4" sm="6">
                  <div>
                    <div id="wallet-balance-chart" className="apex-charts">
                      <ReactApexChart
                        options={this.state.walletOptions}
                        series={this.state.series}
                        type="radialBar"
                        height={302}
                      />
                    </div>
                  </div>
                </Col>

                <Col lg="4" sm="6" className="align-self-center">
                  <div>
                    <p className="mb-2">
                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary"/>{" "}
                      Ethereum
                    </p>
                    <h5>
                      {this.state.ethbal} ETH ={" "}
                      <span className="text-muted font-size-14">$ {this.state.ethvalue}</span>
                    </h5>
                  </div>

                  <div className="mt-4 pt-2">
                    <p className="mb-2">
                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-warning"/>{" "}
                      Ravencoin
                    </p>
                    <h5>
                    {this.state.rvnbal} RVN ={" "}
                      <span className="text-muted font-size-14">$ {this.state.rvnvalue}</span>
                    </h5>
                  </div>

                  <div className="mt-4 pt-2">
                    <p className="mb-2">
                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-success"/>{" "}
                      Chia
                    </p>
                    <h5>
                    {this.state.xchbal} XCH ={" "}
                      <span className="text-muted font-size-14">$ {this.state.xchvalue}</span>
                    </h5>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

export default WalletBalance
