module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID:"577002101012-12ge9avabrig0nv9r4ti7bcmrsalbqhv.apps.googleusercontent.com",
    SECRET: "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",
  },
  facebook: {
    APP_ID: "",
  },
}
