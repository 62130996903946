import PropTypes from 'prop-types';
import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

import firebase from 'firebase/compat/app'

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { set } from 'lodash-es';
import { collection, doc, getDoc, onSnapshot, setDoc } from '@firebase/firestore';

import db from "../../firebase";

class UserProfile extends Component {
  constructor(props) {
    super(props)
    this.state = { email: "", name: "", idx: 1, eth: '', rvn: '', xch: ''}

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }

  // handleValidSubmit
  handleValidSubmit = async (event, values) => {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    const data = {
      eth: values.ethereum,
      rvn: values.ravencoin,
      xch: values.chia
    };

    this.setState({eth: values.ethereum, rvn: values.ravencoin, xch: values.chia})

    const docref = doc(db, "users", obj.uid,)
    await setDoc(docref, data)
    //const res = await db.collection('users').doc('quPOYBBE1PIgQyne9lYx').set(data);
    //this.props.editProfile(values)
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))

      

      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        this.setState({
          name: obj.displayName,
          email: obj.email,
          idx: obj.uid,
        })
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        this.setState({ name: obj.username, email: obj.email, idx: obj.uid, eth: obj.eth })
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate = async (prevProps, prevState, ss) => {
    if (this.props !== prevProps) {

      const obj = JSON.parse(localStorage.getItem("authUser"))
      const docref2 = doc(db, "users", obj.uid,)
      let thisdoc = await getDoc(docref2)
      let ethstring = '';
      let rvnstring = '';
      let xchstring = '';
      if (thisdoc._document != null) {
        ethstring = thisdoc._document.data.value.mapValue.fields.eth.stringValue
        rvnstring = thisdoc._document.data.value.mapValue.fields.rvn.stringValue
        xchstring = thisdoc._document.data.value.mapValue.fields.xch.stringValue
      }
      //console.log(thisdoc._document.data.value.mapValue.fields.eth.stringValue);
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        this.setState({
          name: obj.displayName,
          email: obj.email,
          idx: obj.uid,
          eth: ethstring,
          rvn: rvnstring,
          xch: xchstring,
        })
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        this.setState({ name: obj.username, email: obj.email, idx: obj.uid,eth: obj.eth })
      }
      setTimeout(() => {
        this.props.resetProfileFlag();
      }, 3000);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="User" breadcrumbItem="Profile" />

            <Row>
              <Col lg="12">
                {this.props.error && this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}
                {this.props.success && this.props.success ? (
                  <Alert color="success">{this.props.success}</Alert>
                ) : null}

                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src={avatar}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="align-self-center flex-1">
                        <div className="text-muted">
                          <h5>{this.state.name}</h5>
                          <p className="mb-1">{this.state.email}</p>
                          <p className="mb-0">ETH Address: {this.state.eth}</p>
                          <p className="mb-0">RVN Address: {this.state.rvn}</p>
                          <p className="mb-0">XCH Address: {this.state.xch}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h4 className="card-title mb-4">Change User Name</h4>

            <Card>
              <CardBody>
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    this.handleValidSubmit(e, v)
                  }}
                >
                  <div className="form-group">
                    <AvField
                      name="ethereum"
                      label="Ethereum Address"
                      value={this.state.eth}
                      className="form-control mb-4"
                      placeholder="Enter Ethereum Address"
                      type="text"                      
                    />
                  </div>
                  <div className="form-group">
                    <AvField
                      name="ravencoin"
                      label="Ravencoin Address"
                      value={this.state.rvn}
                      className="form-control mb-4"
                      placeholder="Enter Ravencoin Address"
                      type="text"                      
                    />
                  </div>
                  <div className="form-group">
                    <AvField
                      name="chia"
                      label="Chia Address"
                      value={this.state.xch}
                      className="form-control"
                      placeholder="Enter Chia Address"
                      type="text"                      
                    />
                  </div>
                  <div className="text-center mt-4">
                    <Button type="submit" color="danger">
                      Update profile
                    </Button>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  resetProfileFlag: PropTypes.func
}

const mapStateToProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStateToProps, { editProfile, resetProfileFlag })(UserProfile)
)
