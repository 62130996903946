import React, { Component } from "react"
import PropTypes, { array } from 'prop-types'
import MetaTags from 'react-meta-tags';
//import MiniWidget from "../Dashboard-saas/mini-widget"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input
} from "reactstrap"
import { Link } from "react-router-dom"
import { connect } from "react-redux"


//import action
import { getChartsData } from "../../store/actions"


import MiniWidget from "../Dashboard-crypto/mini-widget"
import WalletBalance from "../Dashboard-crypto/wallet-balance"
import OverView from "../Dashboard-crypto/overview"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import classNames from "classnames";
import axios from 'axios';

import { collection, doc, getDoc, onSnapshot, setDoc } from '@firebase/firestore';

import db from "../../firebase";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

//Bitcoin Chart

const options1 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#f15b22"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
}

const options2 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#3452e1"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
}
//LiteCoin Chart

const options3 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#3AAC59"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
}





class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      eth: '',
      ethbal: 0,
      rvnvalue: 0,
      ethvalue: 0,
      rvnvalue: 0,
      rvn_data: [],
      eth_data: [],
      xch_data: [],
      reports2: [
        { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
        {
          title: "Revenue",
          iconClass: "bx-archive-in",
          description: "$35, 723",
        },
        {
          title: "Average Price",
          iconClass: "bx-purchase-tag-alt",
          description: "$16.2",
        }
      ],
      email: [
        { title: "Week", linkto: "#", isActive: false },
        { title: "Month", linkto: "#", isActive: false },
        { title: "Year", linkto: "#", isActive: true },
      ],
      modal: false,
      subscribemodal: false,
      chartSeries: [],
      periodType: "yearly"
    }
    
  }

  componentDidMount = async () => {

    const obj = JSON.parse(localStorage.getItem("authUser"))
    const docref2 = doc(db, "users", obj.uid,)
    let thisdoc = await getDoc(docref2)
    let ethstring = '';
    if (thisdoc._document != null) {
      ethstring = thisdoc._document.data.value.mapValue.fields.eth.stringValue;
      this.setState({eth: thisdoc._document.data.value.mapValue.fields.eth.stringValue})
    }

    const { onGetChartsData } = this.props;
    setTimeout(() => this.setState({ subscribemodal: true }), 2000);
    onGetChartsData("yearly");
    var number_format = require("number_format-php");


    const a = axios
    .get("https://api.coingecko.com/api/v3/coins/ravencoin")
    .then(response => {
      
      let arrows = '';
        if (response.data.market_data.price_change_percentage_24h < 0) {
          arrows = 'mdi-arrow-down text-danger';
        } else {
          arrows = 'mdi-arrow-up text-success';
        }
        const rvn_prices = new Array();
        axios
        .get("https://api.coingecko.com/api/v3/coins/ravencoin/market_chart?vs_currency=usd&days=30")
        .then(response2 => {
          
          for (let index = 0; index < response2.data.prices.length; index++) {
            rvn_prices[index] = number_format(response2.data.prices[index][1], 4, ".", "");
            
          }

          const series1 = [
            { name: "RVN", data: rvn_prices},
          ]
          
          this.setState({ 
            rvnvalue: response.data.market_data.current_price.usd,
            rvn_data: [
            {
              title: response.data.localization.en,
              icon: "mdi mdi-bitcoin",
              symbol: response.data.symbol.toLowerCase(),
              color: "warning",
              value: "$ "+number_format(response.data.market_data.current_price.usd, 2, ".", ","),
              arrow: arrows[0],
              desc: " ( "+number_format(response.data.market_data.price_change_percentage_24h, 2, ".", ",")+" % )",
              series: series1,
              options: options1,
            }
          ]});
            
            
        });
        
        
    });
    console.log(a);
    axios
    .get("https://api.coingecko.com/api/v3/coins/ethereum")
    .then(response => {
      
      let arrows = '';
        if (response.data.market_data.price_change_percentage_24h < 0) {
          arrows = 'mdi-arrow-down text-danger';
        } else {
          arrows = 'mdi-arrow-up text-success';
        }
        const eth_prices = new Array();
        axios
        .get("https://api.coingecko.com/api/v3/coins/ethereum/market_chart?vs_currency=usd&days=30")
        .then(response2 => {
          
          for (let index = 0; index < response2.data.prices.length; index++) {
            eth_prices[index] = response2.data.prices[index][1].toFixed(2);
            
          }

          const series2 = [
            { name: "ETH", data: eth_prices},
          ]
          
          this.setState({ 
            ethvalue: response.data.market_data.current_price.usd,
            eth_data: [
            {
              title: response.data.localization.en,
              icon: "mdi mdi-bitcoin",
              symbol: response.data.symbol.toLowerCase(),
              color: "warning",
              value: "$ "+number_format(response.data.market_data.current_price.usd, 2, ".", ","),
              arrow: arrows[0],
              desc: " ( "+number_format(response.data.market_data.price_change_percentage_24h, 2, ".", ",")+" % )",
              series: series2,
              options: options2,
            }
          ]});
            
            
        });
        
        
    });

    axios
    .get("https://api.coingecko.com/api/v3/coins/chia")
    .then(response => {
      
      let arrows = '';
        if (response.data.market_data.price_change_percentage_24h < 0) {
          arrows = 'mdi-arrow-down text-danger';
        } else {
          arrows = 'mdi-arrow-up text-success';
        }
        const xch_prices = new Array();
        axios
        .get("https://api.coingecko.com/api/v3/coins/chia/market_chart?vs_currency=usd&days=30")
        .then(response2 => {
          
          for (let index = 0; index < response2.data.prices.length; index++) {
            xch_prices[index] = response2.data.prices[index][1].toFixed(2);
            
          }

          const series3 = [
            { name: "XCH", data: xch_prices},
          ]
          
          this.setState({ 
            xchvalue: response.data.market_data.current_price.usd,
            xch_data: [
            {
              title: response.data.localization.en,
              icon: "mdi mdi-bitcoin",
              symbol: response.data.symbol.toLowerCase(),
              color: "warning",
              value: "$ "+number_format(response.data.market_data.current_price.usd, 2, ".", ","),
              arrow: arrows[0],
              desc: " ( "+number_format(response.data.market_data.price_change_percentage_24h, 2, ".", ",")+" % )",
              series: series3,
              options: options3,
            }
          ],
          isLoading : true
        });                    
     });     
    });
  }


  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }


  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ ...this.state, chartSeries: this.props.chartsData })
    }
  }

  render() {
    return !this.props.isLoading ? (      
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Your Coin Tracker | Dashboard</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Dashboards")}
              breadcrumbItem={this.props.t("Main Dashboard")}
            />
            <Row>
                  {/* mini widgets */}
                  <MiniWidget reports={this.state.eth_data} />
                  <MiniWidget reports={this.state.rvn_data} />
                  <MiniWidget reports={this.state.xch_data} />
                </Row>
            <Row>
              {/* wallet balance */}
              <WalletBalance ethvalue = {this.state.ethvalue} xchvalue = {this.state.xchvalue} rvnvalue = {this.state.rvnvalue} />

              {/* overview */}
              <OverView />
            </Row>

          </Container>
        </div>
        </React.Fragment>
        ) : (
        <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={3000} //3 secs
      />
    )
  }
}

Dashboard.propTypes = {
  t: PropTypes.any,
  isLoading: PropTypes.bool,
  data: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func
}

const mapStateToProps = ({ Dashboard }) => ({
  chartsData: Dashboard.chartsData,
})

const mapDispatchToProps = dispatch => ({
  onGetChartsData: (periodType) => dispatch(getChartsData(periodType)),
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(withTranslation()(Dashboard))

