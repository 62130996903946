import { initFirebaseBackend } from "./helpers/firebase_helper"
import { getFirestore } from "@firebase/firestore";

//import firebase from "firebase";

// Import fackbackend Configuration file
//import fakeBackend from "./helpers/AuthType/fakeBackend"

// Activating fake backend
//fakeBackend()

// Activating fake firebase
const firebaseConfig = {
 apiKey: "AIzaSyDp_AyuyNL165SpCdjJ0eYhmik-7o1TbAk",
 authDomain: "yourcointracker.firebaseapp.com",
 projectId: "yourcointracker",
 storageBucket: "yourcointracker.appspot.com",
 messagingSenderId: "577002101012",
 appId: "1:577002101012:web:aa1b497aa2f89cbb13ca01",
 measurementId: "G-Q75NR8LS66"
};

// init firebase backend
initFirebaseBackend(firebaseConfig);
export default getFirestore();