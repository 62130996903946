import React, { Component } from "react";
import { Col, Card, CardBody, Button, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import axios from 'axios';

import Stats from '../Crypto/CryptoWallet/stats';

import { collection, doc, getDoc, onSnapshot, setDoc } from '@firebase/firestore';

import db from "../../firebase";

class OverView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      reports2: [],
      series: [
        {
        },
      ],
      options: {
        chart: { toolbar: "false" },
        dataLabels: { enabled: !1 },
        stroke: { curve: "smooth", width: 2 },
        markers: { size: 0, style: "hollow" },
        xaxis: {
          type: "datetime",
        },
        tooltip: { x: { format: "dd-MMM HH:mm" } },
        colors: ["#4BB543", "rgba(52, 82, 225, 0.85)", "#f15b22"],
        fill: {
          type: "solid",
        },
        yaxis: [
          {
            title: {
              text: 'Hashrate (MH/s)',
            },           
          }
        ]
      },

      series2: [
        {
        },
      ],
      options2: {
        chart: { toolbar: "false" },
        dataLabels: { enabled: !1 },
        stroke: { curve: "smooth", width: 2 },
        markers: { size: 0, style: "hollow" },
        xaxis: {
          type: "datetime",
          //min: "1636759800",
          //tickAmount: 6,
        },
        tooltip: { x: { format: "dd-MMM HH:mm" } },
        colors: ["#f15b22", "#f15b22"],
        fill: {
          type: "solid",
        },
        yaxis: [
          {
            title: {
              text: 'Hashrate (MH/s)',
            },
          } ]
      },
      activeM: false,
      active6M: false,
      activeY: true,
      activeA: false,
    };
  }

  componentDidMount = async () => {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    const docref2 = doc(db, "users", obj.uid,)
    let thisdoc = await getDoc(docref2)
    let ethstring = '';
    let rvnstring = '';
    let xchstring = '';
    if (thisdoc._document != null) {
      ethstring = thisdoc._document.data.value.mapValue.fields.eth.stringValue;
      rvnstring = thisdoc._document.data.value.mapValue.fields.rvn.stringValue;
      xchstring = thisdoc._document.data.value.mapValue.fields.xch.stringValue;
      this.setState({eth: thisdoc._document.data.value.mapValue.fields.eth.stringValue, rvn: thisdoc._document.data.value.mapValue.fields.rvn.stringValue, xch: thisdoc._document.data.value.mapValue.fields.xch.stringValue})
    }

    const eth_stats = new Array();
    const eth_stats_current = new Array();
    const eth_stats_average = new Array();
    const eth_stats_workers = new Array();
    axios
    .get("https://api.ethermine.org/miner/"+ethstring+"/history")
    .then(response2 => {
      console.log(response2);
      
      for (let index = 0; index < response2.data.data.length; index++) {
        eth_stats[index] = [Number(response2.data.data[index].time+"000"), (response2.data.data[index].reportedHashrate/1000000).toFixed(2)];
        eth_stats_current[index] = [Number(response2.data.data[index].time+"000"), (response2.data.data[index].currentHashrate/1000000).toFixed(2)];
        eth_stats_average[index] = [Number(response2.data.data[index].time+"000"), (response2.data.data[index].averageHashrate/1000000).toFixed(2)];
        eth_stats_workers[index] = [Number(response2.data.data[index].time+"000"), (response2.data.data[index].activeWorkers)];
      }

      axios
      .get("https://api.ethermine.org/miner/"+ethstring+"/currentStats")
      .then(response3 => {

        let workers = 0;
        if (response3.data.data.activeWorkers > 0) {
          workers = response3.data.data.activeWorkers;
        }

        this.setState({series: [
          {
            type: "line",
            name: "Reported Hashrate",
            data: eth_stats
          },
          {
            type: "line",
            name: "Current Hashrate",
            data: eth_stats_current
          },
          {
            type: "line",
            name: "Average Hashrate",
            data: eth_stats_average
          } 
        ],
        reports: [
          {
            icon: "",
            title: "Miners",
            value: workers + "/0",
          },
          {
            icon: "",
            title: "Average Hashrate",
            value: (response3.data.data.averageHashrate/1000000).toFixed(2) + " MH/s",
          },
          {
            icon: "",
            title: "Revenue Per Day",
            value: "$ "+(response3.data.data.usdPerMin*60*24).toFixed(2),
          },
        ],
      })
        
      })      
    })
    const rvn_stats = new Array();
    const rvn_stats_current = new Array();
    axios
    .get("https://api-ravencoin.flypool.org/miner/"+rvnstring+"/dashboard")
    .then(response2 => {
      console.log(response2);
      
      for (let index = 0; index < response2.data.data.statistics.length; index++) {
        //rvn_stats[index] = [Number(response2.data.data.statistics[index].time+"000"), (response2.data.data.statistics[index].reportedHashrate/1000000).toFixed(2)];
        rvn_stats_current[index] = [Number(response2.data.data.statistics[index].time+"000"), (response2.data.data.statistics[index].currentHashrate/1000000).toFixed(2)];
      }
      console.log(rvn_stats);

      axios
      .get("https://api-ravencoin.flypool.org/miner/"+rvnstring+"/currentStats")
      .then(response3 => {
        console.log(response3);

        let workers = 0;
        if (response2.data.data.currentStatistics.activeWorkers > 0) {
          workers = response2.data.data.currentStatistics.activeWorkers;
        }

        this.setState({series2: [
          {
            type: "line",
            name: "Current Hashrate",
            data: rvn_stats_current
          }
        ],
        reports2: [
          {
            icon: "",
            title: "Miners",
            value: workers + "/0",
          },
          {
            icon: "",
            title: "Average Hashrate",
            value: (response3.data.data.averageHashrate/1000000).toFixed(2) + " MH/s",
          },
          {
            icon: "",
            title: "Revenue Per Day",
            value: "$ "+(response3.data.data.usdPerMin*60*24).toFixed(2),
          },
        ],
      })
        
      })    

      this.setState({series2: [
        {
          type: "line",
          name: "Current Hashrate",
          data: rvn_stats_current
        }
      ]})
      
    })
  }


  render() {
    return (
      <React.Fragment>
        <Col xl="6">
          <Row>
            <Stats reports={this.state.reports} />
          </Row>
          <Card>
            <CardBody>
              <h4 className="card-title mb-3">Ethereum Mining Stats (Ethermine)</h4>

              <div>
                <div id="overview-chart" className="apex-charts" dir="ltr">
                  <div className="toolbar d-flex flex-wrap gap-2 justify-content-center">
                    
                  </div>
                  <div id="overview-chart-timeline">
                    <ReactApexChart
                      options={this.state.options}
                      series={this.state.series}
                      type="area"
                      height={240}
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="6">
        <Row>
            <Stats reports={this.state.reports2} />
          </Row>
          <Card>
            <CardBody>
              <h4 className="card-title mb-3">Ravencoin Mining Stats (Flypool)</h4>

              <div>
                <div id="overview-chart" className="apex-charts" dir="ltr">
                  <div className="toolbar d-flex flex-wrap gap-2 justify-content-center">
                    
                  </div>
                  <div id="overview-chart-timeline">
                    <ReactApexChart
                      options={this.state.options2}
                      series={this.state.series2}
                      type="area"
                      height={240}
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default OverView;
